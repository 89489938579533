import React from 'react';
import * as styles from './index.styles';

type Props = {
  type: 'company' | 'selfEmployed' | 'all';
  title?: string;
};

export const UploadableDocumentList: React.FC<Props> = (props) => {
  return (
    <div css={styles.uploadable.wrapper}>
      {props.title && <p css={styles.uploadable.title}>{props.title}</p>}
      <div>
        {(props.type === 'selfEmployed' || props.type === 'all') && (
          <>
            {props.type === 'all' && <p>個人事業主の場合</p>}
            <ul css={styles.uploadable.list}>
              <li>
                開業届の控え（紙・e-Tax）の写真またはPDFファイル
                <span css={styles.uploadable.example}>
                  <a
                    href="/images/example/kaigyo-todoke.png"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    サンプル
                  </a>
                </span>
              </li>
            </ul>
          </>
        )}
        {(props.type === 'company' || props.type === 'all') && (
          <>
            {props.type === 'all' && <p>法人の場合</p>}
            <ul css={styles.uploadable.list}>
              <li>
                法人印鑑証明書の写真またはPDFファイル
                <span css={styles.uploadable.example}>
                  <a
                    href="/images/example/inkan-shomei.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    サンプル
                  </a>
                </span>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
