import { css } from '@emotion/react';
import { alignCss } from '~/styles/alignCss';
import { colors } from '~/styles/colors';
import { layout } from '~/styles/layout';
import { textCss } from '~/styles/textCss';

export const uploadable = {
  wrapper: css`
    background: ${colors.gray7};
    border: 1px solid ${colors.gray5};
    border-radius: ${layout.baseSize.borderRadius}px;
    line-height: 24px;
    ${alignCss({ p: 2 })}
    ${textCss({ size: 's' })}
  `,
  title: css`
    ${textCss({ size: 's', weight: 'bold' })}
  `,
  list: css`
    list-style-type: disc;
    padding-left: 1.5em;
    ${alignCss({ mt: 0.5, mb: 1 })}

    &:last-child {
      ${alignCss({ mt: 0.5 })}
      margin-bottom: 0;
    }
  `,
  example: css`
    white-space: nowrap;

    &::before {
      content: '（';
    }

    &::after {
      content: '）';
    }
  `,
};
